import React, { useRef } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SubjectIcon from "@mui/icons-material/Subject";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const ContactForm = () => {
  const formref = useRef();
  const initialValues = {
    name: "",
    email: "",
    contact: "",
    subject: "",
    message: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    contact: Yup.string()
      .matches(/^\d{10}$/, "Invalid contact number")
      .required("Contact is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });
  const onSubmit = async (values, { resetForm }) => {
    try {
       await emailjs.sendForm(
        "service_3tqydw9",
        "template_fxp3o8u",
        formref.current,
        "SK7BzTO9U0I_wZJyR"
      );
      toast.success("Email sent successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });

      resetForm({ values: initialValues });
    } catch (error) {
      console.log(error.text);
      toast.error("Error sending email. Please try again later.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center gap-10 py-10"
      style={{ backgroundColor: "#0a0a0a" }}
    >
      <div className="flex flex-col justify-center items-center md:gap-5 gap-3">
        <p
          className="md:text-5xl text-3xl font-semibold"
          style={{
            background: "linear-gradient(to right, #F61B1B, #0074CC)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Contact Us
        </p>
        <p className="text-gray-200 md:text-xl text-lg flex flex-col md:flex-row justify-center items-center">
          <span>Grow Your Business With</span>
          <span>&nbsp;Humanify AI</span>
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form
          ref={formref}
          className="w-3/4 lg:w-2/3 xl:w-1/2 bg-[#0a0a0a] px-1 rounded shadow"
        >
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label
                className="block uppercase tracking-wide text-gray-100 text-lg mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <div className="flex">
                <PersonOutlinedIcon
                  style={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    color: "grey",
                  }}
                />
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Your Name"
                  className="appearance-none block w-full bg-transparent text-gray-100 border-b-1 border-b-[#808080] py-3 px-1 leading-tight"
                />
              </div>
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="w-full md:w-1/2 px-3">
              <label
                className="block uppercase tracking-wide text-gray-100 text-lg mb-2"
                htmlFor="contact"
              >
                Contact
              </label>
              <div className="flex">
                <PhoneAndroidOutlinedIcon
                  style={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    color: "grey",
                  }}
                />
                <Field
                  type="text"
                  id="contact"
                  name="contact"
                  placeholder="Enter Contact Number"
                  className="appearance-none block w-full bg-transparent text-gray-100 border-b-1 border-b-[#808080] py-3 px-1 leading-tight"
                />
              </div>
              <ErrorMessage
                name="contact"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-100 text-lg mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <div className="flex">
                <EmailOutlinedIcon
                  style={{
                    width: "45px",
                    height: "45px",
                    display: "flex",
                    color: "grey",
                  }}
                />
                <Field
                  type="email"
                  id="email"
                  name="email"
                  placeholder="xyz@example.com"
                  className="appearance-none block w-full bg-transparent text-gray-200 border-b-1 border-b-[#808080] py-3 px-1 leading-tight"
                />
              </div>
              <ErrorMessage
                name="email"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-100 text-lg mb-2"
                htmlFor="subject"
              >
                Subject
              </label>
              <div className="flex">
                <SubjectIcon
                  style={{
                    width: "45px",
                    height: "45px",
                    display: "flex",
                    color: "grey",
                  }}
                />
                <Field
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder="Subject"
                  className="appearance-none block w-full bg-transparent text-gray-200 border-b-1 border-b-[#808080] py-3 px-1 leading-tight"
                />
              </div>
              <ErrorMessage
                name="subject"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          <div className="flex flex-wrap -mx-3 mb-6">
            <div className="w-full px-3">
              <label
                className="block uppercase tracking-wide text-gray-100 text-lg mb-2"
                htmlFor="message"
              >
                Message
              </label>
              <div className="flex">
                <CreateOutlinedIcon
                  style={{
                    width: "40px",
                    height: "40px",
                    display: "flex",
                    color: "grey",
                  }}
                />
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  placeholder="Your Message"
                  className="no-resize appearance-none block w-full bg-transparent text-gray-300 rounded py-3 px-2 mb-3 leading-tight h-32 resize-none border-b-1 border-b-[#808080]"
                />
              </div>
              <ErrorMessage
                name="message"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          </div>
          {/* Add other fields as needed */}
          <div className="md:flex md:items-center w-[100%]">
            <div className="md:w-2/3">
              <button
                type="submit"
                className="shadow bg-[#F61B1B] focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded w-[100%]"
              >
                Send
              </button>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;
