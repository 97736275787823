import React from "react";
import AboutUs from "../components/about_page/AboutUs";

const About = () => {
  return (
    <>
      <AboutUs></AboutUs>
    </>
  );
};

export default About;
