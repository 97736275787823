import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import bot from "../../images/bot.png";
import ai from "../../images/ai.png";
import web from "../../images/web.png";
import gauratee from "../../images/gauratee.png";
import satisfaction from "../../images/satisfaction.png";
import customer_support from "../../images/customer_support.png";
import ai_consistency from "../../images/ai_consistency.png";
import digital_marketing from "../../images/digital_marketing.png";
import it from "../../images/it.png";

const Service = () => {
  const services = [
    {
      name: "Bot Automation Software",
      img: bot,
      front:
        "Boost efficiency with high-performance bots for online checkout, scheduling, and inventory management.",
      description:
        "Maximize productivity effortlessly with our high-performance bots, specializing in swift online checkout, scheduling, inventory checking, and more. Streamline processes and boost efficiency with our expert automation tools.",
    },
    {
      name: "Customized AI Solutions",
      img: ai,
      front:
        "Boost efficiency with our smart tools, automating chatting, task management, and support for seamless operations.",
      description:
        "Enhance efficiency with our custom smart tools, automating tasks for streamlined chatting, task management, and support. Our chatbots effortlessly qualify leads, allowing you to focus on what matters most while we handle the rest!",
    },
    {
      name: "Web Development and Design",
      img: web,
      front:
        "Innovate Your Digital Landscape with Our AI-Infused Web and App Development Services",
      description:
        "Boost your online presence with our custom web and app development services. We tailor solutions for businesses, integrating AI expertise to set your site apart. Explore our latest innovative web app creation!",
    },
    {
      name: "IT Consultancy",
      img: it,
      front:
        "Enhance operational efficiency with Humanify AI's IT Consulting, delivering seamless technological integration solutions.",
      description:
        "At Humanify AI, our IT Consulting services pave the way for seamless technological integration, optimizing your business for efficiency. Unlock the future with our tailored solutions and unmatched expertise.",
    },
    {
      name: "AI  Consultancy",
      img: ai_consistency,
      front:
        "Explore limitless possibilities with Humanify AI's AI Consulting—where innovation meets strategy for transformative success.",
      description:
        "Explore limitless possibilities with Humanify AI's AI Consulting, where innovation meets strategy. Elevate your IT landscape with our expert guidance, driving transformative solutions for unparalleled success.",
    },
    {
      name: "AI Digital Marketing",
      img: digital_marketing,
      front:
        "Elevate your brand online with Humanify AI's AI-driven campaigns and advanced digital strategies",
      description:
        "Revolutionize your brand's digital presence with Humanify AI's AI Digital Marketing. Harness the power of artificial  for targeted, data-driven campaigns that resonate and drive unparalleled engagement",
    },
  ];

  const [flipped, setFlipped] = useState(Array(services.length).fill(false));

  const handleFlip = (index) => {
    const newFlipped = [...flipped];
    newFlipped[index] = !newFlipped[index];
    setFlipped(newFlipped);
  };

  return (
    <div
      className="flex flex-col items-center gap-10 py-10"
      style={{ backgroundColor: "#0a0a0a" }}
    >
      <div className="flex flex-col justify-center items-center md:gap-5 gap-3">
        <p
          className="md:text-5xl text-3xl font-semibold"
          style={{
            background: "linear-gradient(to right, #F61B1B, #0074CC)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Our Services
        </p>
      </div>
      <div className="card-container flex flex-wrap justify-center xl:gap-28 gap-5 lg:mt-10">
        {services.map((service, index) => (
          <ReactCardFlip
            key={index}
            isFlipped={flipped[index]}
            flipDirection="horizontal"
            className="w-full sm:w-1/2 md:w-2/5 lg:w-2/5 xl:w-1/4 p-4"
          >
            <div
              onClick={() => handleFlip(index)}
              className="md:w-[300px] w-[250px] text-white md:px-10 py-8 px-7  rounded-3xl h-[380px]"
              style={{
                background:
                  "linear-gradient(166deg, rgb(255,0,0) 0%, rgba(0, 116, 204) 141%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <div className="front flex flex-col gap-4">
                <img src={service.img} className="w-16" alt="" />
                <h1 className="md:text-2xl text-xl font-bold">
                  {service.name}
                </h1>
                <p className="text-base">{service.front}</p>
                <p className="flex justify-center items-center">Read More...</p>
              </div>
            </div>
            <div
              onClick={() => handleFlip(index)}
              className="md:w-[300px] w-[250px] text-white md:p-8 p-7  rounded-3xl  h-[380px]"
              style={{
                background:
                  "linear-gradient(166deg, rgb(255,0,0) 0%, rgba(0, 116, 204) 141%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <div className="back flex flex-col gap-4">
                <h1 className="md:text-lg text-xl font-bold">{service.name}</h1>
                <hr />
                <p className="text-[14px]">{service.description}</p>
              </div>
            </div>
          </ReactCardFlip>
        ))}
      </div>
      <div className="flex w-[100%] lg:flex-row flex-col gap-10 lg:gap-0">
        <div className="w-[100%] lg:w-[50%] flex flex-col gap-10 xl:mt-14 xl:pl-40 lg:pl-20 md:px-5">
          <p className="text-white md:text-4xl text-2xl px-5 mt-20 font-semibold">
            We Have The Potential To Enhance Business Efficiency With Help Of AI
          </p>
          <div className="flex flex-col justify-center">
            <div className="flex lg:justify-start px-5 text-gray-400 items-center gap-3">
              <img className="w-10 " src={satisfaction} alt="" />
              <span className="md:text-2xl">100% Clients Satisfaction</span>
            </div>
            <div className="flex px-5 text-gray-400 items-center gap-3">
              <img className="w-10" src={gauratee} alt="" />
              <span className="md:text-2xl">
                Guarantee Of Quality Assurance
              </span>
            </div>
          </div>
        </div>
        <div className="lg:w-[50%] w-[100%] flex justify-center items-center lg:mt-10">
          <img src={customer_support} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Service;
