// Nav.js
import React, { useState } from "react";
import logo from "../../images/humanify.png";
import { useNavigate } from "react-router-dom";

// DarkOverlay component
const DarkOverlay = ({ onClick }) => (
  <div
    className="fixed inset-0 bg-black opacity-50 z-30"
    onClick={onClick}
  ></div>
);

// MenuItem component
const MenuItem = ({ onClick, href, children }) => {
  const handleMenuItemClick = () => {
    if (href === "/#contact") {
      document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
    } else if (href === "/#home") {
      document.getElementById("home").scrollIntoView({ behavior: "smooth" });
    } else if (href === "/#service") {
      document.getElementById("service").scrollIntoView({ behavior: "smooth" });
    } else {
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <p
      className="hover:text-[#F61B1B] transition duration-300 text-xl cursor-pointer"
      onClick={handleMenuItemClick}
    >
      {children}
    </p>
  );
};

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleScrollToContact = () => {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToService = () => {
    document.getElementById("service").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="text-white flex justify-between pl-3 pr-3 lg:pl-10 lg:pr-5 items-center lg:h-28 h-20 bg-[#0A0A0A] sticky top-0 z-50">
      {/* hamburger button */}
      <div className="block lg:hidden">
        <button className="text-white" onClick={handleToggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      <div className="">
        <img src={logo} className="w-32 h-32 " alt="Humanify logo" />
      </div>
      {/* Dark overlay */}
      {isMenuOpen && <DarkOverlay onClick={handleCloseMenu} />}
      {/* Menu items */}
      <div
        className={`lg:hidden fixed left-0 top-0 h-screen w-2/3 md:w-1/3 bg-[#100F0F] z-40 transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform ease-in-out`}
      >
        <div className="flex items-start justify-center mt-20">
          <button
            className="text-white absolute top-5 left-5"
            onClick={handleCloseMenu}
          >
            {/* Close button icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <div className="flex gap-3 flex-col items-center ">
            <div onClick={handleCloseMenu}>
              <MenuItem href="/#home">Home</MenuItem>
            </div>
            <a
              href="/about"
              className="hover:text-[#F61B1B] text-xl cursor-pointer"
            >
              About
            </a>
            <div onClick={handleCloseMenu}>
              <MenuItem href="/#service">Service</MenuItem>
            </div>
            <a
              href="https://forms.gle/D45zT3Dm72he27TS7"
              rel="noreferrer"
              target="_blank"
              className="text-xl hover:text-[#F61B1B] transition duration-300 cursor-pointer"
            >
              Career
            </a>
            <div onClick={handleCloseMenu}>
              <MenuItem href="/#contact">Contact Us</MenuItem>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-10">
        <div className="hidden gap-10 lg:flex mt-2">
          <MenuItem href="/#home">Home</MenuItem>
          <a
            href="/about"
            className="hover:text-[#F61B1B] transition duration-300 text-xl cursor-pointer"
          >
            About
          </a>
          <MenuItem onClick={handleScrollToService}>Service</MenuItem>
          <a
            href="https://forms.gle/D45zT3Dm72he27TS7"
            rel="noreferrer"
            target="_blank"
            className="mb-2 text-lg hover:text-[#F61B1B] transition duration-300 cursor-pointer"
          >
            Career
          </a>
        </div>
        <div>
          <button
            className="hidden lg:block bg-[#F61B1B] rounded-full pl-4 pr-4 pt-2 pb-2 text-xl sm: transition-transform transform-gpu hover:scale-105 active:scale-95"
            onClick={handleScrollToContact}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default Nav;
