import React from "react";
import Lottie from "react-lottie-player";
import animation from "../../animations/Animation.json";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  };

  const handleLearnMoreClick = () => {
    navigate("/about");
  };
  return (
    <div className="bg-[#0A0A0A] h-full text-gray-300 flex flex-col lg:flex-row pt-20 lg:pt-0 lg:pb-12">
      <div className="lg:w-[50%] flex flex-col items-center lg:pl-20 lg:pt-36">
        <div className="text-center lg:text-left">
          <p className="lg:text-[36px] md:text-[30px] text-[24px] font-semibold">
            WELCOME TO
          </p>
          <p
            className="lg:text-[80px] md:text-[62px] text-[40px] font-semibold"
            style={{
              background: "linear-gradient(to right, #F61B1B, #0074CC)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            HUMANIFY AI
          </p>
          <p className="pl-10 lg:pl-0 pr-10 lg:pr-0 lg:text-[26px] md:text-[25px] text-[17px] mt-4 mb-4 text-[#C7C7C7]">
            Elevate your business with our <br /> AI AUTOMATION AGENCY
          </p>
          <div className="flex flex-col lg:flex-row items-center lg:gap-4 gap-2">
            <button
              className="bg-[#F61B1B] text-gray-300 py-2 px-4 rounded-full flex justify-between items-center gap-3 mb-2 lg:mb-0 transition duration-300 ease-in-out transform hover:scale-105 active:scale-95"
              onClick={handleGetStartedClick}
            >
              <p>Get Started</p>
              <div className="bg-[#0a0a0a] text-gray-300 py-1 px-2 rounded-full flex items-center">
                <span>➔</span>
              </div>
            </button>

            <button
              className="bg-[#0a0a0a] text-gray-300 py-2 px-4 rounded-full flex justify-between items-center gap-3 border border-[#F61B1B] transition duration-300 ease-in-out transform  hover:scale-105 active:scale-95"
              onClick={handleLearnMoreClick}
            >
              <p>Learn More</p>
              <div className="bg-[#0a0a0a] text-gray-300 py-1 px-2 rounded-full flex items-center">
                <span>➔</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="lg:w-[50%] lg:pr-10 pt-10">
        <Lottie
          loop
          animationData={animation}
          play
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    </div>
  );
};

export default Main;
