import React from "react";
import { useNavigate } from "react-router-dom";
import people from "../../images/people.png";
import passion from "../../images/passion.png";
import process from "../../images/process.png";
import mission_vision from "../../images/mission_vision.jpg";

const AboutUs = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const cardData = [
    {
      title: "People",
      description:
        "Our people work with the same vision to develop & deliver the best.",
      imageUrl: people,
    },
    {
      title: "Process",
      description:
        "Follow the process to complete the work is what our people do.",
      imageUrl: process,
    },
    {
      title: "Passion",
      description:
        "We work with passion to deliver high-performance & high-quality work.",
      imageUrl: passion,
    },
  ];

  return (
    <div className="bg-[#0A0A0A] text-white py-20 px-5 lg:px-10 ">
      <button
        onClick={handleGoBack}
        className="text-white bg-transparent py-2 px-4"
      >
        {"< Back"}
      </button>
      <div className="text-center mt-10 mb-20">
        <div className="flex flex-col justify-center items-center md:gap-5 gap-3 mb-5">
          <h1
            className="text-4xl lg:text-6xl font-bold"
            style={{
              background: "linear-gradient(to right, #F61B1B, #0074CC)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            About Us
          </h1>
        </div>
        <p className="text-xl lg:text-2xl">
          Boost Your Business Growth With 10X with Our AI Automation
        </p>
        <p className="mt-10 lg:px-28 flex flex-col">
          HumanizeAI is a trailblazing AI agency dedicated to bridging the gap
          between technology and humanity. Our mission is to infuse AI with
          empathy, understanding, and seamless integration into everyday life.
          <br />
          <span className="mt-2">
            We're not just building AI systems; we're crafting companions that
            understand, adapt, and empower. Whether it's revolutionizing
            customer service, simplifying complex processes, or creating
            immersive experiences, HumanizeAI is leading the way
          </span>
        </p>
      </div>

      <div>
        <div className="text-center mb-10">
          <h2 className="text-2xl lg:text-3xl font-bold">
            Humanify AI is standing on 3 pillars
          </h2>
        </div>

        <div className="flex flex-wrap justify-center gap-4 mb-20">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="w-full sm:w-1/2 md:w-2/5 lg:w-2/5 xl:w-1/4 p-4"
            >
              <div className="bg-[#1E1E1E] rounded-lg overflow-hidden shadow-md flex flex-col justify-center items-center p-5 h-full">
                <img
                  src={card.imageUrl}
                  alt={`Card ${index + 1}`}
                  className="w-36"
                />
                <div className="p-4 text-center">
                  <h3 className="text-xl font-bold mb-2">{card.title}</h3>
                  <p className="text-gray-300">{card.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-14">
        <div className="flex w-full lg:flex-row items-center flex-col gap-10 lg:gap-0">
          <div className="w-full lg:w-[50%] flex flex-col lg:px-14 px-5 justify-center items-center gap-6">
            <h3
              className="text-2xl lg:text-4xl font-bold"
              style={{
                background: "linear-gradient(to right, #F61B1B, #0074CC)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Our Mission & Vision
            </h3>
            <p className="text-gray-300 text-sm lg:text-base leading-relaxed">
              {" "}
              &nbsp; &nbsp; &nbsp; At HumanizeAI, we're on a mission to make AI
              more human-friendly by infusing empathy into technology. Our goal
              is to create a future where AI understands and responds to human
              emotions, enriching lives across industries. <br /> &nbsp; &nbsp;
              &nbsp; Our vision is a world where AI is not just smart but also
              emotionally intelligent, seamlessly integrating into daily life to
              provide personalized, empathetic experiences. Through prioritizing
              empathy, we aim to bridge the gap between humans and technology,
              making AI a positive and supportive part of everyday existence.
            </p>
          </div>
          <div className="lg:w-[50%] w-full flex justify-center">
            <img src={mission_vision} alt="" className="w-96" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
