import React from "react";
import Nav from "../components/nav/Nav";
import Main from "../components/content/Main";
import ContactUs from "../components/content/ContactUs";
import Service from "../components/content/Service";
import ScrollToTop from "react-scroll-to-top";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Footer from "../components/footer/Footer";
import About from "../components/content/About";

const Home = () => {
  return (
    <div>
      <ScrollToTop
        width="38px"
        height="20px"
        style={{ background: "#F61B1B", position: "fixed",
        bottom: "20px",
        left: "20px",
        zIndex: "1000" }}
        color="white"
        smooth
      />
      <ToastContainer />
      <Nav></Nav>
      <div id="home">
        <Main></Main>
      </div>
      <About></About>
      <div id="service">
        <Service></Service>
      </div>
      <div id="contact">
        <ContactUs></ContactUs>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
