// Footer.jsx

import React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const handleScrollToContact = () => {
    document.getElementById("contact").scrollIntoView({ behavior: "smooth" });
  };
  const handleScrollToHome = () => {
    document.getElementById("home").scrollIntoView({ behavior: "smooth" });
  };

  const handleScrollToService = () => {
    document.getElementById("service").scrollIntoView({ behavior: "smooth" });
  };
  return (
    <footer className="bg-[#0A0A0A] text-white py-12 lg:px-52 px-10">
      <div className="container mx-auto flex flex-wrap justify-between">
        <div className="w-full lg:w-1/4 mb-8 lg:mb-0">
          <h3 className="text-xl font-bold mb-4">Quick Links</h3>
          <ul>
            <li
              onClick={handleScrollToHome}
              className="mb-2 text-lg hover:text-[#F61B1B] transition duration-300 cursor-pointer"
            >
              Home
            </li>
            <li className="mb-2 text-lg hover:text-[#F61B1B] transition duration-300 cursor-pointer">
              <a href="/about">About</a>
            </li>
            <li
              onClick={handleScrollToService}
              className="mb-2 text-lg hover:text-[#F61B1B] transition duration-300 cursor-pointer"
            >
              Service
            </li>
            <li
              onClick={handleScrollToContact}
              className="mb-2 text-lg hover:text-[#F61B1B] transition duration-300 cursor-pointer"
            >
              Contacts
            </li>
            <a
              href="https://forms.gle/D45zT3Dm72he27TS7"
              rel="noreferrer"
              target="_blank"
              className="mb-2 text-lg hover:text-[#F61B1B] transition duration-300 cursor-pointer"
            >
              Career
            </a>
          </ul>
        </div>

        <div className="w-full lg:w-1/4 mb-8 lg:mb-0 ">
          <div className="mb-4 flex ">
            <h3 className="text-xl font-bold mb-4">Socials</h3>
          </div>
          <div className="flex space-x-4 text-3xl">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.linkedin.com/company/humanify-ai/"
              className="hover:text-[#F61B1B] transition duration-300"
            >
              <FaLinkedin />
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/joinhumanify.ai?igsh=MW1yYzBld2s2bXdoag=="
              className="hover:text-[#F61B1B] transition duration-300"
            >
              <FaInstagram />
            </a>
          </div>
        </div>

        <div className="w-full lg:w-1/4 mb-8 lg:mb-0 flex flex-col gap-3">
          <div className="mb-4 flex flex-col gap-2">
            <h3 className="text-xl font-bold">Contact Us</h3>
            <a href="mailto:humanifyai@gmail.com">humanifyai@gmail.com</a>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-2">Subscribe to Newsletter</h3>
            <div className="flex">
              <input
                type="email"
                placeholder="Enter your email"
                className="p-2 border border-gray-500 text-black rounded-l w-full lg:w-auto"
              />
              <button className="bg-[#F61B1B] text-white rounded-r px-4 hover:bg-opacity-80 transition duration-300">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className="my-6 border-t border-gray-700" />
      <div className="text-center text-lg">
        <p>&copy; 2024 Humanify AI. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
