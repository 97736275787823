import './App.css';
import { NextUIProvider } from "@nextui-org/react";
import Home from './pages/Home';
import About from './pages/About';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <BrowserRouter>
      <NextUIProvider>
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/about" element={<About/>}></Route>
        </Routes>
      </NextUIProvider>
    </BrowserRouter>
  );
}

export default App;
