import React from "react";
import whatsapp from "../../images/whatsapp.png";
import instagram from "../../images/instagram.png";
import telegram from "../../images/telegram.png";
import discord from "../../images/discord.png";
import ecommerce from "../../images/ecommerce.png";
import appointment from "../../images/appointment.png";
import robot from "../../images/robot.png";
import lead from "../../images/lead.png";

const gradientTextStyle = {
  background: "linear-gradient(to right, #F61B1B, #0074CC)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

const buttons = [
  { name: "Lead Generation", logo: lead },
  { name: "AI Website Chatbot", logo: robot },
  { name: "AI Appointments", logo: appointment },
  { name: "Telegram", logo: telegram },
  { name: "Discord", logo: discord },
  { name: "E Commerce", logo: ecommerce },
  { name: "Instagram", logo: instagram },
  { name: "Whatsapp", logo: whatsapp },
];

const About = () => {
  const isMobile = window.innerWidth <= 768; // Adjust the breakpoint as needed

  const visibleButtons = isMobile ? buttons.slice(0, 3) : buttons;

  return (
    <div className=" bg-[#0A0A0A] pt-20 pb-20 flex flex-col gap-5 ">
      <div className="flex flex-col justify-center items-center">
        <div
          className={`title flex flex-col justify-center items-center text-gray-300 gap-3 lg:mb-20 mb-10`}
        >
          <p className="lg:text-3xl text-2xl">Automate To Elevate</p>
          <p
            className="lg:text-5xl font-semibold text-xl"
            style={gradientTextStyle}
          >
            Your Business, Our Bots
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-16 ">
          {visibleButtons.map((button, index) => (
            <button
              key={index}
              className="p-0.5 text-sm font-medium text-white rounded-full group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 dark:text-white focus:ring-4 dark:focus:ring-pink-800"
              style={{
                background:
                  "linear-gradient(166deg, rgb(255,0,0) 0%, rgba(0, 116, 204) 141%)",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <span className="lg:px-10 px-5 lg:py-5 py-3 text-lg transition-all ease-in duration-75 bg-[#0a0a0a] dark:bg-gray-900 rounded-full group-hover:bg-opacity-0 flex">
                <img
                  src={button.logo}
                  alt={button.name}
                  className="h-8 w-8 mr-2"
                />
                <span className="lg:text-xl text-base">{button.name}</span>
              </span>
            </button>
          ))}
          {isMobile && buttons.length > 3 && (
            <span className="text-lg flex mx-auto">
              <span className="lg:text-xl text-base text-white">...</span>
            </span>
          )}
        </div>
        <div>
          <p className="text-white text-xl mt-10">And What You Want ...</p>
        </div>
      </div>
    </div>
  );
};

export default About;
